/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2"
  }, _provideComponents(), props.components), {TableOfContents, LandingPageCta, SideBySide} = _components;
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-swiss-manufacturing-excellence",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-swiss-manufacturing-excellence",
    "aria-label": "phonak swiss manufacturing excellence permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak: Swiss Manufacturing Excellence"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Your world is constantly changing, so why shouldn’t your ", React.createElement(_components.a, {
    href: "/hearing-aids/",
    className: "c-md-a"
  }, "hearing aids"), " adapt with it? Thanks to the latest designs and technology from Phonak, you can head out into the world ready to take on any challenge. Learn more about the history of this great company and nerd out on some of their latest innovations, so you can find the hearing aid that’s right for your needs."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With over 70 years of hearing aid manufacturing experience, Phonak ", React.createElement(_components.a, {
    href: "/hearing-aids/brands/",
    className: "c-md-a"
  }, "hearing aid brand"), " is no newcomer to the world of hearing technology. Since their founding in Switzerland in the late 1940s, Phonak has consistently pushed the limit of what hearing aids can offer."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Dedicated to producing the highest quality devices, Phonak always puts the individual’s needs first when developing their new technology. With precision and reliability at the forefront of their philosophy, Phonak has consistently created some of the most popular and user-friendly hearing aid devices in the industry. Phonak prides itself on offering creative solutions to meet the needs of every individual with hearing loss so you can spend more time enjoying the life you love."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "turn-on-your-world-with-phonak-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#turn-on-your-world-with-phonak-hearing-aids",
    "aria-label": "turn on your world with phonak hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Turn on Your World with Phonak Hearing Aids"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Just because you have hearing loss doesn’t mean you should get left behind. With Phonak’s innovative hearing aid solutions, you can get the care and support you need in a sleek, stylish, easy-to-use package. Check out some of Phonak’s latest technology here:"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "247-hearing-clarity",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#247-hearing-clarity",
    "aria-label": "247 hearing clarity permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "24/7 Hearing Clarity"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Perhaps the biggest innovation from Phonak in recent years is the company’s development of a 100% invisible hearing aid that’s designed to be worn around the clock for days, weeks, and months at a time. This new hearing aid, known as the Phonak Lyric, is placed in the ear canal, keeping it out of sight of the world around you."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Using the anatomy of the inner ear, Lyric captures and amplifies noise to give you a more natural listening experience. Lyric uses micro-engineering and precision technology to put a whole lot of hearing power right into your ear. Since the device is placed inside the ear canal by a trained professional, it can be kept in for months at a time, even during activities like exercising and showering. That’s amazing!"), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "excellent-roger-technology",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#excellent-roger-technology",
    "aria-label": "excellent roger technology permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Excellent Roger Technology"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Not all listening environments are made alike. While some environments are better suited for easy hearing, others are just plain challenging. Thanks to the Roger microphone, however, you can boost your hearing ability in any noisy situation."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "With Roger digital wireless technology, you can bring an additional microphone into any situation to bring the conversation right to your ears. The Roger streams sound to your hearing aids, allowing for improved speech understanding, even in the noisiest of environments, making it perfect for social gatherings and corporate meetings."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "myphonak-app",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#myphonak-app",
    "aria-label": "myphonak app permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "myPhonak App"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Get the most out of your hearing aid devices with the new smartphone app from Phonak. This app allows you to connect directly with your devices so you can adjust them on the go. Plus, it allows you to keep track of your listening experiences with Phonak’s “hearing diary,” which makes it easy to record difficult hearing situations. All of this data can make it easier for your hearing healthcare provider to fine-tune your devices for a better listening experience. Oh, and the myPhonak App even lets you connect with a hearing healthcare professional over video chat for remote support, wherever life takes you."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "phonak-hearing-aids-in-the-news",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-hearing-aids-in-the-news",
    "aria-label": "phonak hearing aids in the news permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Hearing Aids In the News"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "A testament to the quality of Phonak Hearing aids, the Phonak Marvel was named a finalist at the 2019 Edison Awards for the best in innovation. The Phonak Marvel is the first hearing aid in the world to combine a plethora of premium features into one great, attractive package."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "This sleek hearing aid provides unbeatably clear and rich sound quality with universal ", React.createElement(_components.a, {
    href: "/hearing-aids/technology/bluetooth/",
    className: "c-md-a"
  }, "Bluetooth connectivity"), ", which means it can work with any Bluetooth compatible device to stream sound right to your ears. Plus, the device uses lithium-ion rechargeable batteries, so you don’t have to worry about running out of juice in a critical moment. If that wasn’t good enough, the Marvel is also compatible with a suite of smartphone apps to allow for real-time feedback and control on the individual listening experience."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Designed specifically to integrate all of the best features a hearing aid can offer into one package, the Marvel is an industry-leading device, thanks to Phonak’s history of innovative technological advancements. The Marvel is only the beginning, however, of this new age in hearing aid technology, and we’re excited to see where it all goes from here!"), "\n", React.createElement(LandingPageCta, {
    copy: "Get Phonak hearing aids now!",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "phonak-hearing-aids",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-hearing-aids",
    "aria-label": "phonak hearing aids permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Hearing Aids"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-audeo-q.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-audéo-q",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-aud%C3%A9o-q",
    "aria-label": "phonak audéo q permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Audéo Q"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Audéo Q employs cutting-edge audiology technologies by Phonak. One of Audéo Q’s premium functions is the automatic focus on speech, auto StereoZoom. It automatically hones in on the voice of the very person that the user captures visually."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/audeo-q/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-bolero.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-bolero",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-bolero",
    "aria-label": "phonak bolero permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Bolero"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "The Bolero is a high-quality hearing aid manufactured by the Swiss company Phonak. It is designed for those with mild to severe hearing loss that is. Exceptionally sensitive and precisely engineered, the Bolero has been custom-made to handle environments with a high level of ambient noise, while also amplifying soft speech effectively."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/bolero/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-naida.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-naida",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-naida",
    "aria-label": "phonak naida permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Naida"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak has been innovating in the field of hearing aids since they began their journey back in 1947. Their Naida collection covers a broad range of devices that deal with a variety of hearing conditions. While the Naida hearing aid has always been considered the premier device for profound hearing loss, the latest model, the Naida V, represents a major leap forward in the best hearing solutions."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/naida/",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-marvel.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-marvel",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-marvel",
    "aria-label": "phonak marvel permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Marvel"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "As one of the leading hearing aid manufacturers, Phonak is once again changing the face of the industry. In its new Marvel receiver-in-canal (RIC) platform, Phonak leverages its proprietary AutoSense™ 3.0 operating system to deliver an unparalleled natural hearing experience."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/marvel",
    className: "c-md-a"
  }, "Read more"))), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/phonak-virto.png"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "phonak-virto",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#phonak-virto",
    "aria-label": "phonak virto permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Phonak Virto"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Phonak specializes in high-end, custom-built hearing aids for those with moderate to severe hearing loss. Founded in 1947, this Swiss company developed into one of the most advanced manufacturers of wireless communications devices – and hearing aids are a key part of their current product range. The Virto collection is relatively new, and extremely advanced."), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-aids/brands/phonak/virto/",
    className: "c-md-a"
  }, "Read more"))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
